<template>
  <div class="gap-4 max-w-6xl xl:max-w-full mx-auto">
    <div class="card shadow flex flex-col gap-4 items-start">
      <div v-if="nieuw">
        <UIInputCheckbox label="Volgende nummering in volgorde gebruiken" :model-value="form.doc_number === null" @update:model-value="form.doc_number = $event ? null : ''" />
        <UIInput
          v-if="form.doc_number !== null"
          v-model="form.doc_number"
          required
          label="# Nummer"
          class="w-full max-w-2xl"
          placeholder="# Nummer ..."
        />
      </div>

      <UIInputDatePicker
        v-model:date="form.date"
        required
        label="Datum"
        class="text-left"
      />
      <Producten v-model="form.products" />

      <UIInputCheckbox v-model="form.verberg_adressen" label="Verberg adressen?" />
      <UIInput
        v-model="form.notitie"
        textarea
        label="Notitie"
        class="w-full max-w-3xl"
        rows="4"
        placeholder="Notitie ..."
      />

      <button
        type="submit"
        :disabled="!((form.doc_number === null || form.doc_number > 0) && form.date && form.products?.length)"
        class="btn success"
        @click.prevent.stop="onSubmit()"
      >
        Opslaan
      </button>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue'
import clone from 'just-clone'

import mapProductPrices from './mapProductPrices.js'
import Producten from './Producten.vue'

import UIInputCheckbox from '@/components/UI/Input/Checkbox'
import UIInputDatePicker from '@/components/UI/Input/DatePicker.vue'
import UIInput from '@/components/UI/Input/Input.vue'

import { dateString } from '@/functions/formatDate'
import useSearchItem from '@/hooks/useSearchItem'

const props = defineProps({
  nieuw: Boolean,
  disabled: Boolean,
  modelValue: Object,
})

const emit = defineEmits(['update:modelValue', 'submit'])

const searchItem = useSearchItem()

const now = Date.now()
const generateForm = modelValue => {
  const products = modelValue?.products?.length ? modelValue?.products : []
  const obj = {
    ...(modelValue || {}),
    doc_number: modelValue?.doc_number || null,
    doc_number_linked: modelValue?.doc_number_linked || null,
    date: dateString(modelValue?.date || now),
    products: products.filter(el => !(el.rit_id > 0)).map(mapProductPrices),
    notitie: modelValue?.notitie || '',
    verberg_adressen: modelValue?.verberg_adressen || false,
  }
  return obj
}

const form = ref(generateForm(props.modelValue))

watch(form, val => emit('update:modelValue', val))
watch(() => props.modelValue, val => {
  if (val && JSON.stringify(val) !== JSON.stringify(form.value)) {
    form.value = generateForm(val)
  }
})
onMounted(() => emit('update:modelValue', form.value))

const onSubmit = () => {
  const submitData = clone(form.value)
  if (searchItem.value?.id) {
    submitData.client_id = searchItem.value?.id
  }
  emit('submit', submitData)
}
</script>
