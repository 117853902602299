<template>
  <div class="w-full">
    <UIInputLabel v-if="label" :required="required">
      {{ label }}
    </UIInputLabel>
    <small v-if="items?.length" class="block mb-1 italic">
      <strong>Forceer</strong>: Bijvoorbeeld bij niet volledig uitgevoerde rit
    </small>
    <div class="grid max-w-sm gap-2">
      <div v-for="(_, index) in items" :key="`rit-${index}-${items.length}`" class="inline-flex flex-col gap-1 card p-1">
        <div class="flex flex-row justify-between items-start gap-0">
          <UIInput
            v-model="items[index][property]"
            :label="`#${index + 1} ${labelId}`"
            :type="inputType"
            :placeholder="`${labelId} ...`"
            :disabled="disabled"
            class="w-4/12"
          />
          <button :disabled="disabled" class="inline-flex justify-center items-center p-1 group ml-2" @click="items.splice(index, 1)">
            <i class="fas fa-times" :class="{ 'group-hover:text-red-500': !disabled }"></i>
          </button>
        </div>
        <div class="inline-flex flex-col md:flex-row gap-4 items-end">
          <UICheckbox
            v-model="items[index].rit_heen"
            label="Heenreis"
            :disabled="disabled"
          />
          <UICheckbox
            v-model="items[index].rit_retour"
            label="Retourreis"
            :disabled="disabled"
          />
          <UICheckbox
            v-model="items[index].force"
            label="Forceer?"
            :disabled="disabled"
          />
          <UICheckbox
            v-model="items[index].negeer_wachttijden"
            class="whitespace-nowrap"
            label="Wachttijd negeren?"
            :disabled="disabled"
          />
        </div>
      </div>
    </div>
    <div class="mt-2">
      <button class="btn info" :disabled="disabled" @click="items.push({ [property]: '', rit_heen: true, rit_retour: true, force: false, negeer_wachttijden: false })">
        + {{ placeholder }} toevoegen
      </button>
    </div>
  </div>
</template>

<script setup>
import { useVModel } from '@vueuse/core'

// import UISelectFixed from '@/components/UI/Select/Fixed'
import UICheckbox from '@/components/UI/Input/Checkbox.vue'
import UIInput from '@/components/UI/Input/Input.vue'
import UIInputLabel from '@/components/UI/Input/Label.vue'

const props = defineProps({
  disabled: Boolean,
  required: Boolean,
  property: {
    type: String,
    default: 'rit_id',
  },
  inputType: {
    type: String,
    default: 'number',
  },
  label: {
    type: String,
    default: 'Ritten',
  },
  labelId: {
    type: String,
    default: 'Rit ID',
  },
  placeholder: {
    type: String,
    default: 'Rit',
  },
  modelValue: {
    type: Array,
    default() {
      return []
    },
  },
})

const emit = defineEmits(['update:modelValue'])
const items = useVModel(props, 'modelValue', emit, { deep: true, passive: true })
</script>
