<template>
  <div class="w-full">
    <UIInputLabel v-if="label" :required="required">
      {{ label }}
    </UIInputLabel>
    <div class="grid max-w-xl gap-2">
      <div v-for="(_, index) in producten" :key="`prod-${index}-${producten.length}`" class="inline-flex flex-col gap-2 card p-1">
        <div class="flex flex-row justify-between gap-0">
          <UIInputLabel class="w-full">
            {{ `${placeholder} ${index + 1}` }}
          </UIInputLabel>
          <button :disabled="disabled" class="inline-flex justify-center items-center p-1 group" @click="producten.splice(index, 1)">
            <i class="fas fa-times" :class="{ 'group-hover:text-red-500': !disabled }"></i>
          </button>
        </div>

        <UIInput
          v-model="producten[index].description"
          class="w-full"
          label="Omschrijving"
          placeholder="Omschrijving ..."
          :disabled="disabled"
        />
        <div class="inline-flex flex-col md:flex-row gap-2 items-center">
          <UIInput
            v-model="producten[index].price_excl"
            type="number"
            label="Prijs excl."
            placeholder="Prijs excl. ..."
            :disabled="disabled"
            @blur="() => handleChangePriceIncl(index)"
          />
          <UISelectFixed
            v-model="producten[index].vat"
            type="BTW_PERCENT"
            class="w-18"
            label="BTW %"
            placeholder="BTW ..."
            :disabled="disabled"
            @blur="() => handleChangeBTW(index)"
          />
          <UIInput
            v-model="producten[index].price"
            type="number"
            label="Prijs incl."
            placeholder="Prijs incl. ..."
            :disabled="disabled"
            @blur="() => handleChangePriceExcl(index)"
          />
        </div>
        <p class="text-blue-800 p-0 m-0">
          <b>Opgelet</b>: BTW van {{ producten[index].vat }}%. Klopt dit voor dit product?
        </p>
      </div>
    </div>
    <div class="mt-2">
      <button class="btn info" :disabled="disabled" @click="producten.push({ description: '', price_excl: 0, price: 0, vat: 6 })">
        + {{ placeholder }} toevoegen
      </button>
    </div>
  </div>
</template>

<script setup>
import { useVModel } from '@vueuse/core'

import UIInput from '@/components/UI/Input/Input.vue'
import UIInputLabel from '@/components/UI/Input/Label.vue'
import UISelectFixed from '@/components/UI/Select/Fixed'

const props = defineProps({
  disabled: Boolean,
  required: Boolean,
  label: {
    type: String,
    default: 'Producten',
  },
  placeholder: {
    type: String,
    default: 'Product',
  },
  modelValue: {
    type: Array,
    default() {
      return []
    },
  },
})

const emit = defineEmits(['update:modelValue'])
const producten = useVModel(props, 'modelValue', emit, { deep: true, passive: true })

const parsePrice = p => Number(Number(p || 0).toFixed(2).replace(',01', '').replace(',99', ''))

const handleChangePriceIncl = index => {
  if (!producten.value[index]) return
  producten.value[index].price = parsePrice(producten.value[index].price_excl * (1 + producten.value[index].vat / 100))
}
const handleChangePriceExcl = index => {
  if (!producten.value[index]) return
  producten.value[index].price_excl = parsePrice(producten.value[index].price / (1 + producten.value[index].vat / 100))
}
const handleChangeBTW = index => {
  handleChangePriceIncl(index)
}
</script>
